@import '~factor/src/scss/variables';
@import '~factor/src/scss/mixins';

.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  min-height: 3rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1/16+rem;
    background-color: $wild-sand;
  }
}

.title {
  flex: 1 1 auto;
  @include f1-700();
  font-size: 1.25rem;
  line-height: 24/20+em;
  color: #000;
}

.close {

  &:global(.btn-cross) {
    width: 17/16+rem;
    height: 17/16+rem;

    &:before,
    &:after {
      width: 23/16+rem;
      height: .125rem;
    }
  }
}
