@import '~factor/dist/fonts.css';
//@import '~normalize.css/normalize.css';
//@import '~bootstrap/scss/functions';
//@import '~bootstrap/scss/variables';
//@import '~bootstrap/scss/mixins';
//@import '~bootstrap/scss/grid';
//@import '~bootstrap/scss/utilities';
@import '~factor/src/scss/mixins';
@import '../../scss/mixins';
@import '../../scss/table';
@import '../../scss/select';

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  font-size: 16px;
}

body {
  @extend %text;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
